import React from "react";
import moment from "moment";

class DateTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = { snackbars: [] };
    this.format = "";
    this.propsFormat = this.props.format;
  }
  render() {
    return (
      <>
        {this.props.UTCTime === null
          ? null
          : moment(this.props.UTCTime * 1000)
              .local()
              .format(
                this.propsFormat === "DD"
                  ? "dddd"
                  : this.propsFormat === "DMY"
                  ? "MMM Do YYYY"
                  : this.propsFormat === "DMY_HMS_A"
                  ? "MMM DD YYYY h:mm A"
                  : "MMMM Do YYYY, h:mm:ss A"
              )
              .toString()}
      </>
    );
  }
}

export default DateTime;
