import PostmanInterface from "@AuthComponent/PostmanInterface";
import PrivateAuth from "@AuthComponent/PrivateAuth";
import { AuthProvider } from "@Components/Auth/AuthContext";
import Collections from "@Components/AuthComponents/collections/Collections";
import BlockingLoader from "@Components/UI/BlockingLoader";
import DialogManager from "@Components/UI/DialogManager";
import DrawerManager from "@Components/UI/DrawerManager";
import ModalManager from "@Components/UI/ModalManager";
import PopoverManager from "@Components/UI/PopoverManager";
import SnackBarManager from "@Components/UI/SnackBarManager";
import Features from "@Components/onboarding/Features";
import Pricing from "@Components/onboarding/Pricing";
import dialogService from "@Services.App/dialog";
import drawerService from "@Services.App/drawer";
import modalService from "@Services.App/modal";
import popoverService from "@Services.App/popover";
import snackbarService from "@Services.App/snackbar";
import { CssBaseline } from "@mui/material";
import React, { lazy } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Dashboard from "../AuthComponents/dashboard/dashboard";
import AdminAnalytics from "../AuthComponents/AdminAnalytics";
import CollectionDashboard from "../AuthComponents/collections/CollectionDashboard";
import RenderDashboard from "../AuthComponents/dashboard/RenderDashboard";
import LlmDescription from "../onboarding/LlmDescription";
import ErrorBoundary from "./ErrorBoundry";
import PrivateRoute from "./PrivateRoute";

const Templates = lazy(() => import("../AuthComponents/templates1/Templates"));
const Assistants = lazy(() =>
  import("../AuthComponents/assistant/AssistantList")
);

const Assistant = lazy(() => import("../AuthComponents/assistant/Assistant"));
const DataRoom = lazy(() => import("../AuthComponents/dataRoom/DataRoom"));
const DataRoomEditor = lazy(() =>
  import("../AuthComponents/dataRoom/DataFileEditor")
);
const Settings = lazy(() => import("../AuthComponents/Settings/Settings"));
const TeamDocumentation = lazy(() =>
  import("../AuthComponents/Settings/TeamDocumentation")
);

const NotificationsTab = lazy(() =>
  import("../AuthComponents/Notifications/NotificationsTab")
);

const GoogleDriveCodeAuth = lazy(() =>
  import("../AuthComponents/Settings/GoogleDriveCodeAuth")
);

const PageNotFound = lazy(() => import("../UI/PageNotFound"));
const CreateTeam = lazy(() => import("../onboarding/CreateTeam"));
const ForgotPassword = lazy(() => import("../onboarding/ForgotPassword"));
const ResetPassword = lazy(() => import("../onboarding/ResetPassword"));
const ResetEmail = lazy(() => import("../onboarding/ResetEmail"));
const SignUp = lazy(() => import("../onboarding/SignUp"));
const SignIn = lazy(() => import("../onboarding/SignIn"));
const ValidateInvitation = lazy(() =>
  import("../onboarding/ValidateInvitation")
);
const FreeCreditsInfo = lazy(() => import("../onboarding/FreeCreditsInfo"));
const VariablesList = lazy(() =>
  import("../AuthComponents/PostmanInterface/prompt/VariablesList")
);
const ContextVariablesList = lazy(() =>
  import("../AuthComponents/PostmanInterface/context/VariablesList")
);
const Spaces = lazy(() => import("../AuthComponents/dataRoom/Spaces"));
const SpaceFolders = lazy(() =>
  import("../AuthComponents/dataRoom/SpaceFolders")
);

const Main = () => {
  const isLoading = useSelector((state) => state.collectionsSlice.isLoading);
  return (
    <>
      <AuthProvider>
        <CssBaseline />
        <BlockingLoader loading={isLoading} />
        <React.Suspense fallback={<BlockingLoader loading={true} />}>
          <BrowserRouter>
            <ErrorBoundary>
              <Routes path="/">
                <Route path="s1">
                  <Route path="features" element={<Features />} />
                  <Route path="pricing" element={<Pricing />} />
                  <Route path="pricing/:title" element={<LlmDescription />} />
                </Route>
                <Route path="/" element={<SignIn />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/invitation-sign-up" element={<SignUp />} />
                <Route
                  path="/invitation-validate"
                  element={<ValidateInvitation />}
                />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/reset-email" element={<ResetEmail />} />
                <Route
                  path="/create-team"
                  element={<PrivateRoute component={<CreateTeam />} />}
                />
                <Route
                  path="/free-credits"
                  element={<PrivateRoute component={<FreeCreditsInfo />} />}
                />
                <Route path="/onboard" element={<PrivateAuth />}>
                  <Route path="dashboard" element={<RenderDashboard />} />

                  <Route path="collections" element={<Collections />} />
                  <Route
                    path="collections/:collectionId"
                    element={<PostmanInterface />}
                  />
                  <Route
                    path="collections/:collectionId/dashboard"
                    element={<CollectionDashboard />}
                  />
                  <Route
                    path="collections/:collectionId/variables"
                    element={<VariablesList />}
                  />
                  <Route
                    path="collections/:collectionId/context-variables"
                    element={<ContextVariablesList />}
                  />

                  <Route path="templates" element={<Templates />} />
                  <Route path="assistants" element={<Assistants />} />
                  <Route
                    path="assistants/:assistantId"
                    element={<Assistant />}
                  />
                  <Route path="data-room" element={<DataRoom />} />
                  <Route
                    path="data-room/integration/:integrationId"
                    element={<Spaces />}
                  />
                  <Route
                    path="data-room/integration/:integrationId/folders/:spaceId"
                    element={<SpaceFolders />}
                  />
                  <Route
                    path="data-room/:fileId"
                    element={<DataRoomEditor />}
                  />
                  <Route path="settings" element={<Settings />} />
                  <Route path="documentation" element={<TeamDocumentation />} />
                  <Route path="analytics" element={<AdminAnalytics />} />
                  <Route path="notifications" element={<NotificationsTab />} />
                  <Route
                    path="settings/drive"
                    element={<GoogleDriveCodeAuth />}
                  />
                </Route>
                <Route path="*" element={<PageNotFound replace to="/404" />} />
              </Routes>
            </ErrorBoundary>
          </BrowserRouter>
        </React.Suspense>
        <DialogManager ref={(mgr) => dialogService.setManager(mgr)} />
        <SnackBarManager ref={(mgr) => snackbarService.setManager(mgr)} />
        <DrawerManager
          ref={(mgr) => {
            drawerService.setManager(mgr);
          }}
        />
        <ModalManager ref={(mgr) => modalService.setManager(mgr)} />
        <PopoverManager
          ref={(mgr) => {
            popoverService.setManager(mgr);
          }}
        />
      </AuthProvider>
    </>
  );
};
export default Main;
