import { useAuth } from "@/components/Auth/AuthContext";
import { useThemeContext } from "@/components/Themes/ThemeContextProvider";
import calenderIcon from "@/Images/CalenderIcon.svg";
import filterLinesIcon from "@/Images/filter-lines.svg";
import {
  getDashbaordErrors,
  getDashboardDetails,
  updateDashboardErrors,
} from "@/services/app/api";
import snackbar from "@/services/app/snackbar";
import { formatCurrency, formatNumber } from "@/services/generalFunctions";
import { handleLoading } from "@/store/collectionsSlice";
import { BarChart } from "@mui/x-charts/BarChart";
import DateTime from "@UI/DateTime";
import { ErrorOrWarningIcon } from "@UI/IconPack";
import { PercentageChange } from "@UI/PercentageChane";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { data3 } from "./data";

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const WEEKS = ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"];

const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const DashboardData = () => {
  const { mode, colors } = useThemeContext();
  const [period, setPeriod] = useState("day");
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [lineGraphData, setLineGraphData] = useState([]);
  const [errors, setErrors] = useState([]);
  const { activeTeam } = useAuth();
  const dispatch = useDispatch();

  const fetchData = () => {
    const paramsWithPercentage = {
      time: period,
      percentage: true,
      state: 1,
    };

    const paramsWithoutPercentage = {
      time: period,
      state: 1,
    };

    const paramsWithPrevState = {
      time: period,
      percentage: true,
      state: 2,
    };

    const payloadWithPercentage = {
      team_id: activeTeam,
      params: paramsWithPercentage,
    };

    const payloadWithoutPercentage = {
      team_id: activeTeam,
      params: paramsWithoutPercentage,
    };

    const payloadWithPrevState = {
      team_id: activeTeam,
      params: paramsWithPrevState,
    };
    dispatch(handleLoading(true));
    setLoading(true);

    Promise.all([
      getDashboardDetails(payloadWithPercentage),
      getDashboardDetails(payloadWithoutPercentage),
      getDashboardDetails(payloadWithPrevState),
    ])
      .then(([resWithPercentage, resWithoutPercentage, resWithPrevState]) => {
        if (resWithPercentage?.items?.length > 0) {
          setApiData(resWithPercentage.items);
        } else {
          setApiData([]);
        }

        if (resWithoutPercentage?.items?.length > 0) {
          setLineGraphData(resWithoutPercentage.items);
          console.log("response: ", resWithoutPercentage.items);
        } else {
          setLineGraphData([]);
        }
        if (resWithPrevState?.items?.length > 0) {
          setAdditionalData(resWithPrevState.items);
        } else {
          setAdditionalData([]);
        }
      })

      .catch((error) => {
        snackbar.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => {
        dispatch(handleLoading(false));
        setLoading(false);
      });
  };

  const fetchErrors = () => {
    getDashbaordErrors(activeTeam)
      .then((res) => {
        setErrors(res?.items);
      })
      .catch((error) => {
        snackbar.error({
          title: `${error.error && error.error.toString()}`,
        });
      });
  };

  useEffect(() => {
    fetchData();
    fetchErrors();
  }, [activeTeam, period]);

  const defaultResult = { result: { input_tokens: 0, output_tokens: 0 } };

  const getMatchingItem = (lineGraphData, matchFn) =>
    lineGraphData.find(matchFn) || defaultResult;

  const generateXLabels = (length, formatFn) =>
    Array.from({ length }, (_, index) => formatFn(index));

  const getData = () => {
    const now = new Date();
    let XLabels = [];
    let today = new Date();

    switch (period) {
      case "year":
        XLabels = generateXLabels(12, (index) => {
          const monthTime = new Date();
          monthTime.setMonth(today.getMonth() - (11 - index));
          return MONTHS[monthTime.getMonth()];
        });

        const filledYearData = generateXLabels(12, (index) => {
          const monthTime = new Date();
          monthTime.setMonth(today.getMonth() - (11 - index));
          return getMatchingItem(lineGraphData, (item) => {
            const itemDate = new Date(item.timestamp);
            return (
              itemDate.getMonth() === monthTime.getMonth() &&
              itemDate.getFullYear() === monthTime.getFullYear()
            );
          });
        });

        return { data: filledYearData, XLabels };

      case "month":
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );

        const getWeekOfMonth = (date) => {
          const dayOfMonth = date.getDate();
          const startDayOfWeek = firstDayOfMonth.getDay();
          return Math.ceil((dayOfMonth + startDayOfWeek) / 7);
        };

        const currentWeek = getWeekOfMonth(today);

        XLabels = generateXLabels(currentWeek, (index) => `Week ${index + 1}`);

        const filledMonthData = generateXLabels(currentWeek, (index) => {
          const weekStart = new Date(firstDayOfMonth);
          weekStart.setDate(
            firstDayOfMonth.getDate() + index * 7 - weekStart.getDay()
          );

          const weekEnd = new Date(weekStart);
          weekEnd.setDate(weekStart.getDate() + 6);

          return getMatchingItem(lineGraphData, (item) => {
            const itemDate = new Date(item.timestamp);
            return itemDate >= weekStart && itemDate <= weekEnd;
          });
        });

        return { data: filledMonthData, XLabels };

      case "week":
        XLabels = generateXLabels(7, (index) => {
          const dayTime = new Date();
          dayTime.setDate(dayTime.getDate() - (6 - index));
          return DAYS[dayTime.getDay()];
        });

        const filledWeekData = generateXLabels(7, (index) => {
          const dayTime = new Date();
          dayTime.setDate(dayTime.getDate() - (6 - index));
          return getMatchingItem(lineGraphData, (item) => {
            const itemDate = new Date(item.timestamp);
            return (
              itemDate.getDate() === dayTime.getDate() &&
              itemDate.getMonth() === dayTime.getMonth() &&
              itemDate.getFullYear() === dayTime.getFullYear()
            );
          });
        });

        return { data: filledWeekData, XLabels };

      case "day":
        XLabels = generateXLabels(24, (index) => {
          const hourTime = new Date();
          hourTime.setHours(hourTime.getHours() - (23 - index));
          let hour = hourTime.getHours();
          let period = hour >= 12 ? "PM" : "AM";
          hour = hour % 12 || 12;
          return `${hour}${period}`;
        });

        const filledData = generateXLabels(24, (index) => {
          const hourTime = new Date();
          hourTime.setHours(hourTime.getHours() - (23 - index));

          return getMatchingItem(lineGraphData, (item) => {
            const itemDate = new Date(item.timestamp);

            const currentItemHour = Math.floor(
              itemDate.getTime() / (1000 * 60 * 60)
            );
            const targetHour = Math.floor(
              hourTime.getTime() / (1000 * 60 * 60)
            );

            return currentItemHour === targetHour;
          });
        });

        return { data: filledData, XLabels };

      default:
        return { data: lineGraphData, XLabels: [] };
    }
  };

  const { data, XLabels } = getData();
  const InputTokens = data.map((item) =>
    item.result ? item.result.input_tokens : 0
  ) || [0];
  const OutputTokens = data.map((item) =>
    item.result ? item.result.output_tokens : 0
  ) || [0];

  const xLabels = XLabels.map((item) => item) || [0];

  const startUTCTime = 1704499200; // Example: Jan 6, 2024, in Unix timestamp
  const endUTCTime = 1705104000;

  const cost = apiData[0]?.result?.cost ?? 0;

  const clickHandler = (periodValue, stateValue) => {
    setPeriod(periodValue);
  };

  const handleDismiss = (errorId) => {
    dispatch(handleLoading(true));
    const data = {
      error_dismiss: true,
    };

    updateDashboardErrors({ error_id: errorId, data })
      .then((res) => {
        fetchErrors();
      })
      .catch((error) => {
        snackbar.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => dispatch(handleLoading(false)));
  };
  return (
    <main className="">
      <div className="flex justify-between items-start px-[6%] md:space-x-4 w-full mt-[4%]">
        <h1 className="display-md-semibold text-primary">Dashboard</h1>

        <div className="flex gap-[12px]">
          <div className="max-w-[280px] h-[40px] rounded-md border border-primary py-[5px] px-[10px] flex items-center space-x-2">
            <img
              src={calenderIcon}
              alt=""
              className="h-[20px] w-[20px] text-blue-300"
            />
            <div className="truncate">
              <h2 className="text-sm-medium text-primary whitespace-nowrap">
                <DateTime UTCTime={startUTCTime} format="DMY" /> –
                <DateTime UTCTime={endUTCTime} format="DMY" />
              </h2>
            </div>
          </div>

          <div className="max-w-[99px] max-h-[40px] rounded-md border border-primary py-[10px] px-[14px] flex items-center space-x-1">
            <img src={filterLinesIcon} alt="" className="h-[20px] w-[20px]" />
            <div className="max-w-[185px] max-h-[20px]">
              <h2 className="text-sm-medium text-primary">Filters</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="flex px-[6%]  mt-[2%] gap-6">
        <div className="w-3/5">
          <div className="grid grid-cols-3 gap-4">
            <div className="w-full p-4 rounded-xl border border-primary">
              <div className="flex justify-between">
                <h2 className="text-[1rem] text-tertiary">Tokens Consumed</h2>
              </div>
              <div className="flex justify-between mt-[1em] items-center space-x-2  overflow-auto">
                <h2 className="text-lg-semibold text-primary">
                  {formatNumber(apiData[0]?.result?.tokens ?? 0)}
                </h2>
                <div className="">
                  {PercentageChange(
                    apiData[0]?.result?.tokens ?? 0,
                    additionalData[0]?.result?.tokens ?? 0
                  )}
                </div>
              </div>
            </div>

            <div className="w-full p-4 rounded-xl border border-primary">
              <div className="flex justify-between">
                <h2 className="text-[1rem] text-tertiary">
                  Tokens Invocations
                </h2>
              </div>
              <div className="flex justify-between mt-[1em] items-center">
                <h2 className="text-lg-semibold text-primary">
                  {formatNumber(apiData[0]?.result?.api_calls ?? 0)}
                </h2>
                {PercentageChange(
                  apiData[0]?.result?.api_calls ?? 0,
                  additionalData[0]?.result?.api_calls ?? 0
                )}
              </div>
            </div>

            <div className="w-full p-4 rounded-xl border border-primary">
              <div className="flex justify-between">
                <h2 className="text-[1rem] text-tertiary">Total Spend</h2>
              </div>
              <div className="flex justify-between mt-[1em] items-center space-x-2 overflow-auto">
                <h2 className="text-lg-semibold text-primary">
                  {formatCurrency(apiData[0]?.result?.cost?.toFixed(3) ?? 0)}
                </h2>
                <div>
                  {PercentageChange(
                    apiData[0]?.result?.cost?.toFixed(2) ?? 0,
                    additionalData[0]?.result?.cost ?? 0
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-[2%] border   border-secondary p-2 rounded-xl shadow-sm overflow-hidden">
            <h2 className="text-lg-semibold text-primary mb-[2%]">
              Tokens Consumed
            </h2>
            <div>
              <div className="flex justify-start  mb-[2%] space-x-4 w-full">
                {["year", "month", "week", "day"].map((p) => (
                  <button
                    key={p}
                    className={` h-[48px] w-[104px] p-2 text-[1rem] font-semibold rounded-md text-primary transition-colors duration-200 ${
                      period === p ? "bg-secondary" : "bg-transparent"
                    }`}
                    onClick={() => clickHandler(p)}
                  >
                    {p === "year"
                      ? "12 Months"
                      : p === "month"
                      ? "1 Month"
                      : p === "week"
                      ? "7 Days"
                      : "24 Hours"}
                  </button>
                ))}
              </div>

              {loading ? (
                <h1></h1>
              ) : lineGraphData.length > 0 ? (
                <div className="relative overflow-auto flex gap-4 justify-between">
                  <BarChart
                    width={600}
                    height={330}
                    series={[
                      {
                        data: InputTokens,
                        label: "InputTokens",
                        color: "#7A5AF8",
                        stack: "tokens",
                      },
                      {
                        data: OutputTokens,
                        label: "OutputTokens",
                        color: mode === "dark" ? "#1F2937" : "#E5E7EB",
                        stack: "tokens",
                      },
                    ]}
                    slotProps={{
                      legend: { hidden: true },
                    }}
                    leftAxis={null}
                    xAxis={[
                      { data: xLabels, scaleType: "band", id: "point-x-axis" },
                    ]}
                  />
                </div>
              ) : (
                <div className="text-center text-primary m-auto h-40 flex items-center w-80 justify-center">
                  <div>No data available</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="w-2/5 flex flex-col  gap-2">
          {data3.map((item, index) => (
            <Link
              key={index}
              to={item.to}
              className="w-full h-[88px] p-[20px] rounded-xl border border-primary bg-primary flex items-center"
            >
              <div className="flex items-center space-x-4 w-full">
                <div className="h-[48px] w-[48px] rounded-lg border border-primary flex items-center justify-center">
                  <img
                    src={item.icon}
                    alt=""
                    className="object-contain h-[28px] w-[28px]"
                  />
                </div>
                <div>
                  <h1 className="text-primary text-md">{item.title}</h1>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div className=" px-[6%]  mt-[2%] mx-[6%] border border-secondary rounded-xl">
        <div className="w-full max-h-[40px]  flex justify-between mt-[2%] mb-[16px]">
          <h2 className="text-lg-semibold  text-primary">Errors & issues</h2>
          <button className="max-w-[112px] max-h-[40px]  rounded-md border border-secondary py-[10px] px-[14px] bg-secondary text-sm-semibold text-primary">
            View report
          </button>
        </div>
        <div className=" max-h-[400px] p-4  mb-[20px] overflow-auto">
          {errors?.length > 0 ? (
            errors?.map((item, index) => (
              <div
                key={index}
                className="bg-primary border-1 border-primary p-4 m-2 rounded-lg "
              >
                <div>
                  <ErrorOrWarningIcon color={true ? "#F04438" : "#F79009"} />
                </div>
                <p className="text-sm text-secondary">{item?.error}</p>
                <div className="flex mt-4 gap-2">
                  <h4
                    className="text-sm-semibold text-tertiary cursor-pointer"
                    onClick={() => handleDismiss(item?.id)}
                  >
                    Dismiss
                  </h4>
                </div>
              </div>
            ))
          ) : (
            <p className="text-primary  text-center">No errors found</p>
          )}
        </div>
      </div>
    </main>
  );
};

export default DashboardData;
